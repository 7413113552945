import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React, {useState} from "react";
import {useTypedSelector} from "src/store";

import {fetchPracticeBySlug} from "../../../_services/api";
import {PracticeId} from "../../../constants/practiceIds";
import {ignoreArrays} from "../../../utils/ignoreArrays";
import {useEffectAsync} from "../../_common/Carbon";
import NextImage from "../NextImage";
import TextMeApp from "../TextMeApp";
import styles from "./Main.module.scss";

type Feature = {
  icon: string;
  copy: string;
  color: string;
  bg: string;
  activeColor: string;
};
const Main = ({gA}: any) => {
  const {practices, isMobile} = useTypedSelector(({config}) => config);
  const router = useRouter();
  const i18n = useTranslation();

  const carbonPractice = practices?.findById(PracticeId.CARBON);

  const [practice, setPractice] = useState(carbonPractice);

  const features: Feature[] = [
    {
      icon: "calendar",
      copy: i18n.t("Book same-day appointments"),
      color: "#A3E1C2",
      bg: "#A3E1C2",
      activeColor: "#19191b",
    },
    {
      icon: "medical-app",
      copy: i18n.t("Own your digital medical records"),
      color: "#EBC627",
      bg: "#EBC627",
      activeColor: "#19191b",
    },
    {
      icon: "icon_video",
      copy: i18n.t("Chat or video call your doc"),
      color: "#E15C18",
      bg: "#E15C18",
      activeColor: "#fff",
    },
    {
      icon: "drugs-pills",
      copy: i18n.t("Fill your prescriptions"),
      color: "#58B3CA",
      bg: "#58B3CA",
      activeColor: "#fff",
    },
  ];

  const [selected, setSelected] = useState(features[0].icon);

  useEffectAsync(async () => {
    const {practiceSlug: practiceSlugQuery} = router.query;
    const practiceSlug = ignoreArrays(practiceSlugQuery);
    if (practiceSlug && practiceSlug !== carbonPractice?.slug) {
      const newPractice = await fetchPracticeBySlug(practiceSlug);
      if (newPractice.name) setPractice(newPractice);
    }
  }, []);

  const {downloadDeepLink, slug} = practice || {
    downloadDeepLink: "https://crbn.app.link/nijKU2YXJH",
    slug: "direct-urgent-care",
  };

  const renderBall = (f: Feature) => (
    <div
      key={f.icon}
      style={{"--color": f.color, "--bg": f.bg, "--activeColor": f.activeColor}}
      className={`${styles.ball} ${selected === f.icon ? "active" : ""}`}
      onClick={() => setSelected(f.icon)}
    >
      <span className={`${styles.icon} cIcon-${f.icon}`} />
      <span className={styles.title}>{f.copy}</span>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <p className="color-nevada">{i18n.t("Available on iPhone and Android")}</p>
        <h1 className={styles.header}>{i18n.t("Get the Carbon Health App")}</h1>
        <div className={styles.formWrapper}>
          <TextMeApp practiceSlug={slug} downloadDeepLink={downloadDeepLink} gA={gA} />
        </div>
        {!isMobile && (
          <div className={styles.wewill}>
            {i18n.t("We will send you a link to download the Carbon Health app")}
          </div>
        )}
      </div>
      {!isMobile && (
        <div className={styles.bottom}>
          <div className={styles.bottomInner}>
            <div className={styles.bottomSide}>
              <div className={styles.ballWrapper}>
                {features.slice(0, 2).map(f => renderBall(f))}
              </div>
            </div>
            <div className={styles.bottomMiddle}>
              <NextImage
                width={460}
                height={874}
                src="download-phone"
                priority
                alt={i18n.t("An iPhone showing a patient's next appointment.")}
              />
            </div>
            <div className={styles.bottomSide}>
              <div className={styles.ballWrapper}>{features.slice(2, 4).map(renderBall)}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
